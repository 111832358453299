export const recentTrade = [
   { 	currencyIcon: "cc BTC-alt",
   	statusClass: 'text-success', 
   	status: 'Buy', 
   	price: 16245.79, 
   	total: 16245.79
   },
   {currencyIcon: "cc ETC",statusClass: 'text-danger', status: 'Sell', price: 43224.06, total: 16245.79},
   {currencyIcon: "cc BTC-alt",statusClass: 'text-success', status: 'Buy', price: 61213.941, total: 16245.79},
   {currencyIcon: "cc ETC",statusClass: 'text-danger', status: 'Sell', price: 9313.0122, total: 16245.79},
   {currencyIcon: "cc LTC",statusClass: 'text-danger', status: 'Sell', price: 10243.11, total: 16245.79},
   {currencyIcon: "cc BTC-alt",statusClass: 'text-success', status: 'Buy', price: 123872.07, total: 16245.79},
   {currencyIcon: "cc LTC",statusClass: 'text-danger', status: 'Sell', price: 10243.11, total: 16245.79},
   {currencyIcon: "cc ETC",statusClass: 'text-danger', status: 'Sell', price: 43224.06, total: 16245.79},
   {currencyIcon: "cc BTC-alt",statusClass: 'text-success', status: 'Buy', price: 61213.941, total: 16245.79},
];